<template>
  <div>
    <b-col sm="12" v-if="formGroup && user.isAdmin">
      <form-group label="Building (if not selected, default is used)"
                  :use-horizontal="false">
        <treeselect v-model="serverParams.buildingId"
                    v-else
                    placeholder="Select Building"
                    v-if="user.isAdmin"
                    @input="onSelect"
                    :options="buildings" />
      </form-group>
    </b-col>
    <treeselect v-model="serverParams.buildingId"
                placeholder="Select Building"
                v-if="!formGroup && user.isAdmin"
                @input="onSelect"
                :options="buildings" />
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  export default {
    name: "BuildingDropdown",
    props: {
      onSelect: Function,
      serverParams: Object,
      formGroup: false,
    },
    data: () => ({
      buildings: [],
    }),
    created() {
      const self = this;
      if (self.user.isAdmin) {
        this.getBuildings();
        this.getDefaultBuilding();
      }
    },
    computed: {
      ...mapGetters("auth", ["user"]),
    },
    methods: {
      getDefaultBuilding() {
        const self = this;
        self.$store
          .dispatch("apis/get", {
            url: "/common/configuration/mcst.defaultbuilding",
            params: self.serverParams
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              this.serverParams.buildingId = response.data.value;
            }
          });
      },
      getBuildings() {
        const self = this;
        self.$store
          .dispatch("apis/get", {
            url: "/buildings",
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.buildings = response.data.data.map((x) => ({
                id: x.id,
                label: x.name,
              }));
            }
          });
      }
    }
  }
</script>
