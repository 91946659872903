<template>
  <div class="animated fadeIn">
    <CardTable
      :title="title"
      :columns="table.columns"
      :rows="table.rows"
      :isLoading="table.isLoading"
      :pageSize="serverParams.pageSize"
      :pageNumber="serverParams.pageNumber"
      :totalPage="table.totalPage"
      :totalRecords="table.totalRecords"
      @onRowSelected="onRowSelected"
      @onPageChange="onPageChange"
    >
      <template #action>
        <div class="card-header-actions">
          <b-button
            :to="{ name: 'UserManagementCreate' }"
            size="sm"
            variant="primary"
          >
            Create New
          </b-button>
        </div>
      </template>
      <template #filter>
        <SectionFilter :serverParams="serverParams" @onChange="getAll" />
      </template>
      <template #cell(fullName)="data">
        {{ data.item.fullName }}
        <b-badge v-if="data.item.username == user.username" variant="success">
          It's You!
        </b-badge>
      </template>
      <template #cell(actived)="data">
        <span v-show="data.item.actived" class="text-success">Active</span>
        <span v-show="!data.item.actived" class="text-danger">Inactive</span>
      </template>
    </CardTable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { userManagement as columns } from "@/shared/columns";
import SectionFilter from "./SectionFilter.vue";

export default {
  components: {
    SectionFilter,
  },
  data: () => ({
    title: "User Management",
    serverParams: {
      pageNumber: 1,
      pageSize: 100,
      keyword: null,
      fullName: null,
      role: null,
      actived: null,
    },
    table: {
      isLoading: true,
      columns,
      rows: [],
      totalPage: 0,
      totalRecords: 0,
    },
  }),
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  created() {
    const self = this;
    self.getAll();
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onRowSelected(items) {
      const self = this;
      if (items[0].role.id == 1) {
        self.$message.warning({
          zIndex: 10000,
          message: "Please use the tenant management page to change this data",
        });
      } else {
        self.$router.push({
          name: "UserManagementUpdate",
          params: {
            id: items[0].id,
          },
        });
      }
    },
    onPageChange(params) {
      this.updateParams({ pageNumber: params });
      this.getAll();
    },
    getAll() {
      const self = this;

      self.table.isLoading = true;
      self.$store
        .dispatch("apis/get", {
          url: "/user",
          params: self.serverParams,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.table.rows = response.data.data;
            self.table.totalPage = response.data.totalPage;
            self.table.totalRecords = response.data.totalData;
          }
          self.table.isLoading = false;
        });
    },
  },
};
</script>