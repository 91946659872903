export const reportMonthlyAccountDetails = [
  {
    label: "#",
    key: "sn",
    thClass: "align-middle",
  },
  {
    label: "Month",
    key: "date",
    thClass: "align-middle",
  },
  {
    label: "Account#",
    key: "accountId",
    thClass: "align-middle",
  },
  {
    label: "Name",
    key: "name",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Curr Deposit",
    key: "deposit",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
    sortable: true,
  },
  {
    label: "Usage (kWh)",
    key: "usageKwh",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
    sortable: true,
  },
  {
    label: "Usage $",
    key: "usageCharges",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  },
  {
    label: "GST Incurred",
    key: "gstIncurred",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
    sortable: true,
  },
  {
    label: "Late Payment",
    key: "latePaymentFees",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
    sortable: true,
  },
  {
    label: "Req. Additional top up",
    key: "additionalDepositRequired",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
    sortable: true,
  },
  {
    label: "Adjustment",
    key: "adjustmentAmount",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
    sortable: true,
  },
  {
    label: "Amount",
    key: "amount",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
    sortable: true,
  },
];

export const reportDebtorAging = [
  {
    label: "#",
    key: "sn",
    thClass: "align-middle",
  },
  {
    label: "Account#",
    key: "accountId",
    thClass: "align-middle",
  },
  {
    label: "Name",
    key: "name",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Deposits",
    key: "deposit",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
    sortable: true,
  },
  {
    label: "Total Outstanding",
    key: "totalOutstandingAmount",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
    sortable: true,
  },
  /*
  {
    label: "Overdue Amount",
    key: "overdueAmount",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  },
  */
  // {
  //   label: "Current Amount",
  //   key: "currentAmount",
  //   thClass: "align-middle text-right",
  //   tdClass: "align-middle text-right",
  //   sortable: true,
  // },
];

export const unpaidInvoice = [
  {
    label: "Invoice Number",
    key: "invoiceNo",
    thClass: "align-middle",
  },
  {
    label: "Usage (kWh)",
    key: "usageKwh",
    thClass: "align-middle text-right",
    tdClass: "text-right",
  },
  {
    label: "Period",
    key: "period",
    thClass: "align-middle",
  },
  {
    label: "Payment Due Date",
    key: "paymentDueDate",
    thClass: "align-middle",
  },
  {
    label: "Amount (SGD)",
    key: "totalAmount",
    thClass: "align-middle text-right",
    tdClass: "text-right",
  },
  {
    label: "Record Payment (SGD)",
    key: "recordPayment",
    thClass: "align-middle text-right",
    tdClass: "text-right",
  },
  /*{
    label: "Outstanding Balance (SGD)",
    key: "outstandingBalance",
    thClass: "align-middle text-right",
    tdClass: "text-right",
  },*/
];

export const recentUpdatedPayments = [
  {
    label: "Account",
    key: "account.name",
    thClass: "align-middle",
  },
  {
    label: "Unit#",
    key: "account.unit",
    thClass: "align-middle",
  },
  {
    label: "Payment Date",
    key: "paymentDate",
    thClass: "align-middle",
  },
  {
    label: "Created On",
    key: "createdAt",
    thClass: "align-middle",
  },
];

export const recentUpdatedInvoices = [
  {
    label: "Account",
    key: "accountName",
    thClass: "align-middle",
  },
  {
    label: "Total Amount",
    key: "totalAmount",
    thClass: "align-middle text-right",
    tdClass: "text-right",
  },
  {
    label: "Created On",
    key: "createdAt",
    thClass: "align-middle",
  },
];

export const recentUpdatedAccounts = [
  {
    label: "Account#",
    key: "accountId",
    thClass: "align-middle",
  },
  {
    label: "Name",
    key: "name",
    thClass: "align-middle",
  },
  {
    label: "Unit#",
    key: "unit",
    thClass: "align-middle",
  },
  {
    label: "Created On",
    key: "createdAt",
    thClass: "align-middle",
  },
];

export const recentUpdatedMeters = [
  {
    label: "Meter Serial#",
    key: "serialNo",
    thClass: "align-middle",
  },
  {
    label: "Unit#",
    key: "unitNumber",
    thClass: "align-middle",
  },
  {
    label: "Reading Date",
    key: "currentReadingDate",
    thClass: "align-middle",
  },
  {
    label: "Uploaded Date",
    key: "createdAt",
    thClass: "align-middle",
  },
];

export const meterReadingsPerAccount = [
  {
    label: "Meter Serial#",
    key: "meter.serialNo",
    thClass: "align-middle",
  },
  {
    label: "Unit#",
    key: "unitNumber",
    thClass: "align-middle",
  },
  {
    label: "Current Reading (kWh)",
    key: "currentReadingKwh",
    thClass: "align-middle text-right",
    tdClass: "text-right",
  },
  {
    label: "Current Reading Date",
    key: "currentReadingDate",
    thClass: "align-middle",
  },
  {
    label: "Current Usage (kWh)",
    key: "currentUsageKwh",
    thClass: "align-middle text-right",
    tdClass: "text-right",
  },
  {
    label: "Meter Type",
    key: "meterReading.meterType",
    thClass: "align-middle",
  },
  {
    label: "Download Latest Invoice",
    key: "download",
    thClass: "align-middle text-center",
    tdClass: "text-center",
  },
];

export const meterManagement = [
  {
    label: "Building",
    key: "building.label",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Meter Serial#",
    key: "serialNo",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Unit#",
    key: "units",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Account#",
    key: "accountNo",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Account Name",
    key: "accountName",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Installed Date",
    key: "installedDate",
    thClass: "align-middle",
    sortable: true,
  },
];

export const payment = [
  {
    label: "Payment Date",
    key: "paymentDate",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Name",
    key: "account.name",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Account#",
    key: "account.accountId",
    thClass: "align-middle",
    sortable: true,
  },
  //{
  //  label: "Invoice#",
  //  key: "invoices",
  //  thClass: "align-middle",
  //  sortable: true,
  //},
  {
    label: "Payment Method",
    key: "paymentMethod.label",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Amount (SGD)",
    key: "amount",
    thClass: "align-middle text-right",
    tdClass: "text-right",
    sortable: true,
  },
  /*{
    label: "Outstanding Balance (SGD)",
    key: "outstandingBalance",
    thClass: "align-middle text-right",
    tdClass: "text-right",
  },*/
  {
    label: "Remarks",
    key: "remarks",
    thClass: "align-middle",
    sortable: true,
  },
];

export const invoiceManagement = [
  {
    label: "Name",
    key: "accountName",
    thClass: "align-middle",
  },
  {
    label: "Account#",
    key: "accountId",
    thClass: "align-middle",
  },
  {
    label: "Usage (kWh)",
    key: "usageKwh",
    thClass: "align-middle text-right",
    tdClass: "text-right",
  },
  {
    label: "Invoice Number",
    key: "invoiceNo",
    thClass: "align-middle",
  },
  {
    label: "Period",
    key: "period",
    thClass: "align-middle",
  },
  {
    label: "Amount (SGD)",
    key: "currentCharge",
    thClass: "align-middle text-right",
    tdClass: "text-right",
  },
  {
    label: "Outstanding Balance (SGD)",
    key: "outstandingBalance",
    thClass: "align-middle text-right",
    tdClass: "text-right",
  },
  {
    label: "Additional Deposit (SGD)",
    key: "additionalDeposit",
    thClass: "align-middle text-right",
    tdClass: "text-right",
  },
  {
    label: "",
    key: "action",
    thClass: "align-middle",
  },
];

export const userManagement = [
  {
    label: "Full Name",
    key: "fullName",
    thClass: "align-middle",
  },
  {
    label: "Username (Email or Account#)",
    key: "username",
    thClass: "align-middle",
  },
  {
    label: "Role",
    key: "role.label",
    thClass: "align-middle",
  },
  {
    label: "Status",
    key: "actived",
    thClass: "align-middle",
  },
];

export const configuration = [
  {
    label: "Key",
    key: "key",
    thClass: "align-middle",
  },
  {
    label: "Value",
    key: "value",
    thClass: "align-middle",
  },
];

export const EmailTemplate = [
  {
    label: "#",
    key: "index",
    thClass: "align-middle",
  },
  {
    label: "Name",
    key: "name",
    thClass: "align-middle",
  },
  {
    label: "Subject",
    key: "subject",
    thClass: "align-middle",
  },
  {
    label: "Is Active",
    key: "isActive",
    thClass: "align-middle",
  },
  {
    label: "",
    key: "action",
    thClass: "align-middle",
  },
];

export const EmailReminder = [
  {
    label: "",
    key: "selected",
    thClass: "align-middle",
  },
  {
    label: "Account Number",
    key: "account.accountId",
    thClass: "align-middle",
  },
  {
    label: "Name",
    key: "account.name",
    thClass: "align-middle",
  },
  {
    label: "OutStanding Balance ",
    key: "outstandingBalance",
    thClass: "align-middle",
  },

];
export const adjustments = [
  {
    label: "Account#",
    key: "accountId",
    thClass: "align-middle",
  },
  {
    label: "Name",
    key: "accountName",
    thClass: "align-middle",
  },
  {
    label: "Period",
    key: "period",
    thClass: "align-middle",
  },
  {
    label: "Adjust Reason",
    key: "title",
    thClass: "align-middle",
  },
  {
    label: "Amount (SGD)",
    key: "amount",
    thClass: "align-middle",
  },
  {
    label: "Status",
    key: "isApplied",
    thClass: "align-middle",
  },
  {
    label: "",
    key: "action",
    thClass: "align-middle",
  }
]
export const buildingManagement = [
  {
    label: "Building Id",
    key: "id",
    thClass: "align-middle",
  },
  {
    label: "Building Name",
    key: "name",
    thClass: "align-middle",
    sortable: true,
  }
]
export const unitManagement = [
  {
    label: "Unit",
    key: "unit",
    thClass: "align-middle",
    sortable: true,
  }
]
export const accountManagement = [
  {
    label: "Account#",
    key: "accountId",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Name",
    key: "name",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "UEN",
    key: "uen",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Address",
    key: "address",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Unit#",
    key: "unit",
    thClass: "align-middle",
    sortable: true,
  },
  //new added
  {
    label: "Postal",
    key: "postalCode",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Email",
    key: "joinedEmails",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Deposit",
    key: "deposit",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Due(days)",
    key: "lateFeeDueDateOffset",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Auto Compute",
    key: "autoComputeYesNo",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Late%",
    key: "lateFeePercent",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Monthly Late Fee",
    key: "minimumLateFee",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Meters",
    key: "meters",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Outstanding",
    key: "outstandingBalance",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Status",
    key: "deleted",
    thClass: "align-middle",
  },
];

export const meterReadings = [
  {
    label: "Account#",
    key: "account.accountId",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Meter Serial#",
    key: "serialNo",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Unit#",
    key: "unitNumber",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Previous Reading (kWh)",
    key: "previousReadingKwh",
    thClass: "align-middle text-right",
    tdClass: "text-right",
    sortable: true,
  },
  {
    label: "Previous Reading Date",
    key: "previousReadingDateStr",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Current Reading (kWh)",
    key: "currentReadingKwh",
    thClass: "align-middle text-right",
    tdClass: "text-right",
    sortable: true,
  },
  {
    label: "Current Reading Date",
    key: "currentReadingDate",
    thClass: "align-middle",
    sortable: true,
  },
  {
    label: "Current Usage (kWh)",
    key: "currentUsageKwh",
    thClass: "align-middle text-right",
    tdClass: "text-right",
    sortable: true,
  },
  {
    label: "Last Reading Period",
    key: "period",
    thClass: "align-middle",
    sortable: true,
  },
  {
    key: "action",
    thClass: "align-middle text-center",
    tdClass: "text-right",
  }
];

export const meterReadingsHistory = [
  {
    label: "Reading Period",
    key: "period",
    thClass: "align-middle",
  },
  {
    label: "Account#",
    key: "account.accountId",
    thClass: "align-middle",
  },
  {
    label: "Meter Serial#",
    key: "serialNo",
    thClass: "align-middle",
  },
  {
    label: "Previous Reading Date",
    key: "previousReadingDate",
    thClass: "align-middle",
  },
  {
    label: "Previous Reading (kWh)",
    key: "previousReadingKwh",
    thClass: "align-middle",
  },
  {
    label: "Current Reading Date",
    key: "currentReadingDate",
    thClass: "align-middle",
  },
  {
    label: "Current Reading (kWh)",
    key: "currentReadingKwh",
    thClass: "align-middle",
  },
  {
    label: "Usage of The Month (kWh)",
    key: "currentUsageKwh",
    thClass: "align-middle",
  },
];
export const reportAccountStatements = [
  //{
  //  label: "Account#",
  //  key: "accountId",
  //  thClass: "align-middle",
  //},
  {
    label: "Date",
    key: "txnDateStr",
    thClass: "align-middle",
  },
  {
    label: "Transaction",
    key: "transaction",
    thClass: "align-middle",
  },
  {
    label: "Monthly Charges",
    key: "amount",
    thClass: "align-middle",
  },
  {
    label: "Payment Amount",
    key: "payment",
    thClass: "align-middle",
  },
  {
    label: "Balance",
    key: "balance",
    thClass: "align-middle",
  },
  {
    label: "Invoice No/Payment Mode",
    key: "remarks",
    thClass: "align-middle",
  }
];
